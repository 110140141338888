<template>
  <div>
    <svg
      id="e8bidr8f2t061"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="-5 -5 110 110"
      shape-rendering="geometricPrecision"
      text-rendering="geometricPrecision"
      class="rseImage"
    >
      <g id="e8bidr8f2t062" fill="currentColor">
        <path
          id="e8bidr8f2t063"
          d="M49.958000,28.688000C40.680000,28.688000,33.154000,36.213000,33.154000,45.498000C33.154000,48.554000,34.011000,
 51.396000,35.394000,53.889000C39.520000,61.327000,40.615000,65.502000,41.290000,69.543000C41.854000,72.930000,42.634000,73.717000,45.192000,73.717000C46.283000,
 73.717000,48.061000,73.717000,49.958000,73.717000C51.859000,73.717000,53.641000,73.717000,54.725000,73.717000C57.288000,73.717000,58.069000,72.930000,58.632000,
 69.543000C59.309000,65.500000,60.403000,61.324000,64.528000,53.889000C65.916000,51.397000,66.768000,48.555000,66.768000,45.498000C66.771000,36.212000,59.245000,
 28.688000,49.958000,28.688000Z"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="e8bidr8f2t064"
          d="M55.971000,74.934000L43.958000,74.934000C43.458000,
 74.934000,43.055000,75.336000,43.055000,75.834000L43.055000,76.436000C43.055000,76.932000,43.458000,77.336000,43.958000,77.336000L55.971000,77.336000C56.464000,77.336000,
 56.868000,76.932000,56.868000,76.436000L56.868000,75.834000C56.870000,75.336000,56.464000,74.934000,55.971000,74.934000Z"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="e8bidr8f2t065"
          d="M55.971000,78.537000L43.958000,78.537000C43.458000,78.537000,43.055000,78.937000,43.055000,79.437000L43.055000,80.039000C43.055000,80.535000,43.458000,
 80.937000,43.958000,80.937000L55.971000,80.937000C56.464000,80.937000,56.868000,80.535000,56.868000,80.039000L56.868000,79.437000C56.870000,78.938000,56.464000,78.537000,55.971000,78.537000Z"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="e8bidr8f2t066"
          d="M55.971000,82.141000L43.958000,82.141000C43.458000,82.141000,43.055000,82.543000,43.055000,83.041000L43.055000,
 83.643000C43.055000,84.141000,43.458000,84.543000,43.958000,84.543000L55.971000,84.543000C56.464000,84.543000,56.868000,84.141000,56.868000,83.643000L56.868000,83.041000C56.870000,82.543000,
 56.464000,82.141000,55.971000,82.141000Z"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="e8bidr8f2t067"
          d="M45.458000,85.744000L54.471000,85.744000C54.471000,87.545000,
 52.065000,88.746000,49.958000,88.746000C47.861000,88.746000,45.458000,87.545000,45.458000,85.744000Z"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_10"
          class="light_path_2"
          d="M69.264000,16.854000C70.796000,14.171000,66.659000,11.762000,65.114000,14.440000C64.305000,15.839000,58.452000,25.981000,58.452000,
 25.981000L62.614000,28.383000C62.614000,28.383000,67.813000,19.366000,69.260000,16.865000C69.263000,16.864000,69.263000,16.862000,69.263000,16.862000L69.264000,
 16.854000L69.264000,16.854000Z"
          transform="matrix(0.50000000000000 0.86602540378444 -0.86602540378444 0.50000000000000 62.81039903396076 -21.44595178691979)"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_8"
          class="light_path_3"
          d="M69.264000,16.854000C70.796000,14.171000,66.659000,11.762000,65.114000,14.440000C64.305000,
 15.839000,58.452000,25.981000,58.452000,25.981000L62.614000,28.383000C62.614000,28.383000,67.813000,19.366000,69.260000,16.865000C69.263000,16.864000,69.263000,
 16.862000,69.263000,16.862000L69.264000,16.854000L69.264000,16.854000Z"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_7"
          class="light_path_6"
          d="M78.633000,26.247000L78.633000,26.247000L78.630000,26.250000C78.622000,26.251000,78.622000,26.251000,78.622000,26.251000C76.510000,
 27.472000,67.456000,32.700000,67.456000,32.700000L69.853000,36.860000C69.853000,36.860000,78.929000,31.621000,81.041000,30.402000C83.727000,28.854000,81.316000,
 24.713000,78.633000,26.247000Z"
          transform="matrix(0.50000000000000 0.86602540378444 -0.86602540378444 0.50000000000000 63.54934778289547 -20.17345273989469)"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_6"
          class="light_path_1"
          d="M78.633000,26.247000L78.633000,26.247000L78.630000,
 26.250000C78.622000,26.251000,78.622000,26.251000,78.622000,26.251000C76.510000,27.472000,67.456000,32.700000,67.456000,32.700000L69.853000,36.860000C69.853000,
 36.860000,78.929000,31.621000,81.041000,30.402000C83.727000,28.854000,81.316000,24.713000,78.633000,26.247000Z"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_5"
          class="light_path_5"
          d="M52.452000,11.071000C52.452000,11.800000,52.452000,23.883000,52.452000,23.883000L47.646000,
 23.883000C47.646000,23.883000,47.646000,11.799000,47.646000,11.071000C47.646000,7.973000,52.452000,7.973000,52.452000,11.071000Z"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_4"
          class="light_path_2"
          d="M30.737000,16.854000C29.205000,14.171000,33.343000,11.762000,
 34.889000,14.440000C35.697000,15.839000,41.550000,25.981000,41.550000,25.981000L37.392000,28.383000C37.392000,28.383000,32.184000,19.366000,30.740000,16.865000C30.740000,
 16.864000,30.740000,16.862000,30.740000,16.862000L30.737000,16.854000L30.737000,16.854000Z"
          transform="matrix(0.50000000000000 -0.86602540378444 0.86602540378444 0.50000000000000 -12.35114138032189 65.15750369095090)"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_3"
          class="light_path_3"
          d="M30.737000,16.854000C29.205000,14.171000,33.343000,11.762000,34.889000,
 14.440000C35.697000,15.839000,41.550000,25.981000,41.550000,25.981000L37.392000,28.383000C37.392000,28.383000,32.184000,19.366000,30.740000,16.865000C30.740000,
 16.864000,30.740000,16.862000,30.740000,16.862000L30.737000,16.854000L30.737000,16.854000Z"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_2"
          class="light_path_6"
          d="M21.371000,26.247000L21.371000,26.247000L21.371000,26.250000C21.378000,26.251000,21.378000,26.251000,21.378000,26.251000C23.494000,27.472000,
 32.545000,32.700000,32.545000,32.700000L30.143000,36.860000C30.143000,36.860000,21.067000,31.621000,18.956000,30.402000C16.273000,28.854000,18.683000,24.713000,21.371000,
 26.247000Z"
          transform="matrix(0.50000000000000 -0.86602540378444 0.86602540378444 0.50000000000000 -12.94095402770768 66.42812628411406)"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
        <path
          id="light_path_1"
          class="light_path_1"
          d="M21.371000,26.247000L21.371000,26.247000L21.371000,26.250000C21.378000,26.251000,
 21.378000,26.251000,21.378000,26.251000C23.494000,27.472000,32.545000,32.700000,32.545000,32.700000L30.143000,36.860000C30.143000,36.860000,21.067000,31.621000,18.956000,
 30.402000C16.273000,28.854000,18.683000,24.713000,21.371000,26.247000Z"
          transform="matrix(1 0 0 1 0.60899999999998 0.00000000499995)"
          opacity="0"
          stroke="none"
          stroke-width="1"
        />
      </g>
    </svg>
  </div>
</template>

<script>
import anime from "animejs";
export default {
  mounted() {
    var animationDuration = 10000;
    var upDuration = 3000;
    anime({
      targets: ".light_path_1",
      duration: upDuration - 500,
      delay: 500,
      opacity: [0, 0.8],
      endDelay: animationDuration,
      easing: "linear",
      loop: true,
    });
    anime({
      targets: ".light_path_2",
      duration: upDuration - 200,
      delay: 200,
      opacity: [0, 0.8],
      endDelay: animationDuration,
      easing: "linear",
      loop: true,
    });
    anime({
      targets: ".light_path_6",
      duration: upDuration - 1000,
      delay: 1000,

      opacity: [0, 0.8],
      easing: "linear",
      endDelay: animationDuration,
      loop: true,
    });
    anime({
      targets: ".light_path_3",
      duration: upDuration - 500,
      delay: 500,
      opacity: [0, 0.9],
      endDelay: animationDuration,
      easing: "linear",
      loop: true,
    });
    anime({
      targets: ".light_path_5",
      duration: upDuration - 200,
      delay: 200,
      opacity: [0, 0.8],
      loop: true,
      easing: "linear",
      endDelay: animationDuration,
    });
  },
};
</script>
